<template>
  <div class="error">
    <p class="text form__text">{{ title }}</p>
    <input
      type="tel"
      placeholder="+380 (99) 999-99-99"
      @input="emitter"
      class="form__tel input"
      v-model="new_value"
    />
    <p class="form__email-error" v-show="error">{{ error }}</p>
    <picture
      ><source srcset="/img/flag-ukraine.svg" type="image/webp" />
      <img
        src="/img/flag-ukraine.svg"
        alt=""
        class="form__password-flag-ukraine"
    /></picture>
  </div>
</template>

<script>
export default {
  name: "Phone",
  props: ["title", "field", "error", 'value'],
  data() {
    const already_value = this.value ? this.value : '';
    return {
      new_value: already_value
    };
  },
  methods: {
    emitter() {
      this.$emit("value-input", {
          field: this.field,
          value: this.new_value
      });
    },
  },
};
</script>